<template>
  <v-dialog
    v-model="previewImgDialog"
    max-width="500"
  >
    <v-card>
      <v-card-text>
        <v-img
          :src="previewImgUrl || require('@/assets/images/white.jpeg')"
          max-width="500"
        ></v-img>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    previewImgDialog: {
      type: Boolean,
      required: true,
    },
    previewImgUrl: {
      type: String,
      default: '',
    },
  },
}
</script>
